import { Box, Button, Container, Input, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'

import { axiosContext } from '@/context/axios'

const App = () => {
  const { axiosInstance } = useContext(axiosContext)
  const router = useRouter()

  const [loginFormValues, setLoginFormValues] = useState<loginFormValues>({
    email: '',
    password: '',
  })

  const handleInputChange = (name: string, value: string) => {
    setLoginFormValues((prev) => ({ ...prev, [name]: value }))
  }

  const login = async () => {
    await axiosInstance.get('/sanctum/csrf-cookie')
    await axiosInstance.post('/api/login', {
      email: loginFormValues.email,
      password: loginFormValues.password,
    })
    router.replace('/')
  }

  return (
    <Container>
      <Box sx={{ mt: '150px', justifyContent: 'center' }}>
        <Typography variant="h6" align="center">
          LOGIN
        </Typography>
        <Box sx={{ mt: '50px' }}>
          <Input
            fullWidth
            type="text"
            name="email"
            placeholder="メールアドレス"
            value={loginFormValues.email}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
        </Box>
        <Box sx={{ mt: '30px', mb: '50px' }}>
          <Input
            fullWidth
            type="password"
            name="password"
            placeholder="パスワード"
            value={loginFormValues.password}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
        </Box>
        <Box textAlign="center">
          <Button onClick={login} variant="contained" sx={{ my: 2 }}>
            ログイン
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

App.requireAuth = false

export default App

type loginFormValues = {
  [key in 'email' | 'password']: string
}
